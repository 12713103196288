import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/surveys', config);
}

const show = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/surveys/' + id);
}
const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/surveys/' + id);
}
const getForEvaluate = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/surveys/' + id + '/evaluate');
}

const evaluate = async (id, data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/surveys/' + id + '/evaluate', data);
}

const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    if (data.id) {
        return Api.put('/surveys/', data);
    }
    return Api.post('/surveys', data);
}
const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/surveys/' + id, formData);
}
const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/surveys/' + id);
}
const report = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/surveys/report', config);
}
const ReportFilter = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('surveys/report/filter');
}

const answerReport = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/surveys/answer-report', config);
}

const surveyResults = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/surveys/results', config);
}

const commentAnswerReport = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/surveys/comment-answer-report', config);
}

const instructorList = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/surveys/instructor-list', config);
}

const instructorResults = async (semesterId, instructorId, sectionId,) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get(`/surveys/instructor-results/${semesterId}/${instructorId}/${sectionId}`);
}
const downloadSurveyReport = async (surveyId, semesterId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let semester = semesterId != null ? '?semester_id='+semesterId : ''
    return Api.get(`/surveys/faculty-report/${surveyId}${semester}`);
}
const foreignStudentReportExcel = async (surveyId, semesterId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let semester = semesterId != null ? '?semester_id='+semesterId : ''
    return Api.get(`/surveys/yu-faculty-report/${surveyId}${semester}`);
}

const instructorSurveyReport = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/surveys/instructor-survey-report', config);
}


export default {
    getAll,
    show,
    get,
    store,
    update,
    del,
    getForEvaluate,
    evaluate,
    report,
    ReportFilter,
    answerReport,
    surveyResults,
    commentAnswerReport,
    instructorList,
    instructorResults,
    downloadSurveyReport,
    foreignStudentReportExcel,
    instructorSurveyReport
}
